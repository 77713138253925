import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import {
  Scrollbar,
  type GridColDef,
  type GridColumnVisibilityModel,
  type GridPinnedColumns,
} from '@profgeosoft-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';
import { hasValue } from 'src/packages/utils/has-value';
import {
  HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS,
  type TableSettingsManager,
} from 'src/services/table-settings-service/table-settings-manager';

import type { DragEndEvent } from '@dnd-kit/core';
import type { ObservableMap } from 'mobx';
import type { ReactNode } from 'react';
import type { TControlView } from 'src/services/directory-service/types';

import { DisplaySettingItem } from './display-setting-item';

type Props = {
  displayManager: TableSettingsManager;
  columns: GridColDef[];
  fields: ObservableMap<string, TControlView>;
  minVisibleColumns?: number;
};

export const DisplaySettings = wrap<Props>(function DisplaySettings({
  fields,
  columns,
  displayManager,
  minVisibleColumns = 1,
}) {
  const { t } = useTranslation();

  const handleColumnVisibilityChange = (column: string, value: boolean) => {
    const { settings } = displayManager;

    const newVisibilityModel: GridColumnVisibilityModel = {
      ...settings.columnsVisibility,
      [column]: value,
    };

    displayManager.onColumnVisibilityChange(newVisibilityModel);
  };

  const handleUnpinClick = (field: string) => {
    const { settings } = displayManager;

    if (!settings.pinnedColumns || Object.keys(settings.pinnedColumns).length < 1) return;

    for (const side in settings.pinnedColumns) {
      if (side !== 'left' && side !== 'right') return;

      if (!settings.pinnedColumns[side]?.includes(field)) continue;

      const changedSidePinedModel = settings.pinnedColumns[side]?.filter((column) => column !== field);
      const changedPinModel: GridPinnedColumns = { ...settings.pinnedColumns, [side]: changedSidePinedModel };

      displayManager.onPinChange(changedPinModel);
    }
  };

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { settings } = displayManager;

      if (!settings?.columnsOrderModel) return;

      const activeField = event.active.id;
      const overField = event.over?.id;

      if (!hasValue(overField) || !hasValue(activeField)) {
        return;
      }

      const newIndex = settings.columnsOrderModel.findIndex((item) => item === overField);
      const oldIndex = settings.columnsOrderModel.findIndex((item) => item === activeField);

      const reorderedColumns = arrayMove([...settings.columnsOrderModel], oldIndex, newIndex);

      displayManager.onColumnsOrderModelChange(reorderedColumns);
    },
    [displayManager],
  );

  const checkIsColumnVisibilityDisabled = (columnField: string): boolean => {
    const { settings } = displayManager;

    if (!settings.columnsOrderModel) return false;

    const visibleColumns = settings.columnsVisibility;

    if (!visibleColumns) return false;

    const isColumnVisible = visibleColumns[columnField] === true || visibleColumns[columnField] === undefined;
    let visibleColumnsCount = 0;

    settings.columnsOrderModel.forEach((item) => {
      if (visibleColumns[item] === true || visibleColumns[item] === undefined) {
        visibleColumnsCount += 1;
      }
    });

    const columnDef = columns.find((column) => column.field === columnField);

    if (!columnDef) return true;

    if (columnDef.hideable === false) {
      return true;
    } else {
      if (!isColumnVisible) {
        return false;
      }

      return visibleColumnsCount <= minVisibleColumns;
    }
  };

  const renderItems = (renderedColumns: string[]): ReactNode => {
    return renderedColumns.map((column) => {
      if (HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS.includes(column)) {
        return null;
      }

      const fieldId = fields.get(column)?.fieldId;
      const fieldName = t(`labels:${fieldId}.label`);

      return (
        <DisplaySettingItem
          key={column}
          field={column}
          title={fieldName}
          unpinColumn={handleUnpinClick}
          isVisibilitySwitcherDisabled={checkIsColumnVisibilityDisabled(column)}
          isVisible={
            displayManager.settings.columnsVisibility?.[column] === undefined
              ? true
              : displayManager.settings.columnsVisibility[column]
          }
          isPinned={displayManager.displaySettingPinnedColumns.includes(column)}
          onColumnVisibilityChange={handleColumnVisibilityChange}
        />
      );
    });
  };

  if (!displayManager.displaySettingColumns) return null;

  return (
    <>
      <Scrollbar>
        {renderItems(displayManager.settings.pinnedColumns?.left || [])}
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
          <SortableContext items={displayManager.displaySettingColumns.map((item) => item)}>
            {renderItems(displayManager.displaySettingColumns)}
          </SortableContext>
        </DndContext>
        {renderItems(displayManager.settings.pinnedColumns?.right || [])}
      </Scrollbar>
    </>
  );
});
